<template>
    <base-layout />
</template>
<script>
export default {
    created() {
        console.log('user has signed out')
        this.$router.push('/signin')
    }
}
</script>